import React from "react"
import Spacer from "../components/spacer"
import Layout from "../components/layout"
import { P, TextLink, TextBlockHeading, PageHeading } from '../components/typography'
import SEO from "../components/seo"

const Services = () => {
  const textData = [
    { header: 'Services', 
      body: 
        <>
          <P margin="mb-6">
          We offer an advanced type of acoustic wave therapy. This protocol addresses the underlying cause of erectile 
            dysfunction – impaired blood vessels and poor blood flow. As men get 
            older, the blood vessels that supply blood to the penis begin to close 
            up and decay.
          </P>
          <P>
            Our procedure uses pulsating sound waves to treat the 
            penis and penis area. The gentle pulsating waves open up existing 
            blood vessels and stimulate the growth of new blood vessels. This 
            leads to improved blood flow to this sensitive area. The result is 
            increased sensitivity and stronger, longer lasting, more natural, 
            and spontaneous erections.
          </P>
        </>
    },
    {
      header: 'Why Wave Protocol?',
      body: 
        <>
          <P margin="mb-6">
            Men have reported improved sexual performance and positive results 
            after receiving treatment. Men who have received these treatments 
            reported much stronger and lasting erections.
          </P>
          <P margin="mb-6">
            In most cases, our treatment results in long lasting improvement.
          </P>
          <P>
            This therapy is based on <TextLink className="active" to="/science/">extensive clinical research.</TextLink>
          </P>
        </>
    },
    {
      header: 'Who Is It For?',
      body: 
        <>
          <P margin="mb-6">
            Most men with erectile dysfunction are candidates for acoustic wave 
            therapy treatment. The treatment is great for men who have not 
            responded to other forms of treatment, such as oral medications, 
            injections, or <TextLink className="active" to="/blog/does-low-testosterone-cause-erectile-dysfunction">
            testosterone replacement therapy.</TextLink>
          </P>
          <P>
            Whether your ED is mild or severe, 
            <TextLink className="active" to="/science"> acoustic wave therapy has 
            been shown to help.</TextLink>
          </P>
        </>
    },
    {
      header: 'What Is The Treatment Like?',
      body: 
        <>
          <P margin="mb-6">
            The acoustic wave therapy treatment is quick, fairly painless and 
            easy. It is an outpatient procedure that takes 25 minutes or less. 
            You’ll be able to go home as soon as it’s done and most men plan 
            sexual activity for as early as that same evening.
          </P>
          <P>
            Our medical provider or technician will position the machine 
            over the penile shaft and adjust the machine during treatment 
            to ensure the largest area possible is treated. Generally, 
            patients undergo several treatments over several weeks, but every 
            patient is different. More or fewer treatments may be 
            recommended to achieve the best results. Most patients report only 
            mild discomfort during the procedure.  Patients report only 
            feeling slight vibrations with no recovery time of any kind.
          </P>
        </>
    },
    {
      header: 'Does It Work?',
      body: 
        <>
          <P margin="mb-6">Yes. Acoustic Wave Therapy has been shown to increase blood 
            flow and can lead to better erections. You can read the 
            extensive medical research <TextLink className="active" to="/science">here.</TextLink></P>
          <P margin="mb-6">In a study titled "Men's Power-Pressure Wave Erectile Regeneration 
            Therapy", researchers from Cambridge University reported 
            "95% of the men had improved erections based on the SHIM 
            score measurement tool at the last treatment". That 
            basically means that 95% of men that received the 
            treatment had improved erections after acoustic wave therapy 
            treatments.
          </P>
          <P margin="mb-6">It's time to regain your love life!</P>
          <P>There is no need to suffer with ED any longer, <TextLink className="active" to="/contact-us">contact us today!</TextLink></P>
        </>
    },
  ]

  return (
    <Layout>
          <SEO 
            title="Treatment Services We Offer" 
          />
          <div className="flex justify-center">
            <div className="text-center">
              {textData.map((textEntry, index) => ( 
                <div className={`px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-4xl ${index !== 0 ? 'pt-12' : ''}`}>
                  {index === 0 ? 
                    <PageHeading>{textEntry.header}</PageHeading> :
                    <TextBlockHeading wide>{textEntry.header}</TextBlockHeading>
                  }
                    <Spacer />
                  <div className="px-2">
                    {textEntry.body}
                  </div>
                </div>
              ))}
            </div>
          </div>
    </Layout>
  )
}

export default Services
